"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
(function () {
  var COOKIE_NAME = 'km_cookie_consents';
  var COOKIE_PROPERTY_JS = '_js';
  var DATA_JS = 'data-js';
  var DATA_COOKIE_CONSENTS_UPDATE_URL = 'data-cookie-consents-update-url';
  var VISIBLE_CLASS_NAME = '--visible';
  var SWITCHER_LABEL_CLASS_NAME = 'cookieConsent__sectionConsent__switcherLabel__text';
  var DATA_COOKIE_CONSENTS_DURATION_SECONDS = 'data-cookie-consents-duration-seconds';
  var DATA_COOKIE_CONSENT_CODENAMES = 'data-cookie-consent-codenames';
  var DATA_INIT = 'data-init';
  window.km_cookieConsentInit = function () {
    var form = document.getElementById('cookieConsent__form');
    if (!form || form.hasAttribute(DATA_INIT)) {
      return;
    }
    var buttonSelection = document.getElementById('cookieConsent__buttonSelection');
    var buttonGrantSelection = document.getElementById('cookieConsent__buttonGrantSelection');
    var buttonGrantNone = document.getElementById('cookieConsent__buttonGrantNone');
    var buttonGrantAll = document.getElementById('cookieConsent__buttonGrantAll');
    var sectionStorageConsent = document.getElementById('cookieConsent__sectionStorageConsent');
    var sectionSelection = document.getElementById('cookieConsent__sectionSelection');
    var consentCheckboxes = getCookieConsentCheckboxes(form);
    buttonSelection.addEventListener('click', function (event) {
      event.preventDefault();
      buttonGrantSelection.removeAttribute('hidden');
      sectionSelection.removeAttribute('hidden');
      buttonSelection.setAttribute('hidden', null);
      sectionStorageConsent.setAttribute('hidden', null);
      if (buttonGrantNone) {
        buttonGrantNone.setAttribute('hidden', null);
      }
    });
    if (buttonGrantNone) {
      buttonGrantNone.addEventListener('click', function () {
        for (var key in consentCheckboxes) {
          if (!consentCheckboxes[key].disabled) {
            consentCheckboxes[key].checked = false;
          }
        }
      });
    }
    buttonGrantAll.addEventListener('click', function () {
      for (var key in consentCheckboxes) {
        consentCheckboxes[key].checked = true;
      }
    });
    var _loop = function _loop(key) {
      consentCheckboxes[key].onchange = function () {
        var _iterator = _createForOfIteratorHelper(consentCheckboxes[key].parentElement.getElementsByClassName(SWITCHER_LABEL_CLASS_NAME)),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var label = _step.value;
            if (label.classList.contains(VISIBLE_CLASS_NAME)) {
              label.classList.remove(VISIBLE_CLASS_NAME);
            } else {
              label.classList.add(VISIBLE_CLASS_NAME);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      };
    };
    for (var key in consentCheckboxes) {
      _loop(key);
    }
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      var consentData = getConsentData(consentCheckboxes);

      // If cookie was already persisted before, send the form.
      // It solves the case when a cookie is httpOnly and cannot be change in JS.
      if (form.getAttribute(DATA_JS) !== 'true') {
        fetch(form.getAttribute(DATA_COOKIE_CONSENTS_UPDATE_URL), {
          method: 'POST',
          body: JSON.stringify(consentData),
          credentials: 'same-origin',
          // To send cookies.
          redirect: 'follow',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            // Because of Nette's isAjax()
            'Content-Type': 'application/json'
          }
        })["catch"](function (response) {
          console.info(response);
        })["catch"](function (err) {
          console.error(err);
        });
      }
      var currentTimestamp = new Date().getTime();
      var durationTimestamp = parseInt(form.getAttribute(DATA_COOKIE_CONSENTS_DURATION_SECONDS)) * 1000;
      var expirationDate = form.hasAttribute(DATA_COOKIE_CONSENTS_DURATION_SECONDS) ? new Date(currentTimestamp + durationTimestamp) : null;
      if (setCookieConsentsCookie(consentCheckboxes, expirationDate)) {
        gtag('consent', 'update', consentData);
        window.dataLayer.push({
          consent: consentData
        });
        window.km_modal.destroy(true);
      }
    });
    form.setAttribute(DATA_INIT, 1);
  };
  onDOMReady(function () {
    window.km_cookieConsentInit();
  });

  /**
   * @param {HTMLFormElement} form
   */
  function getCookieConsentCheckboxes(form) {
    var elements = form.elements;
    var result = {};
    for (var i = 0; i < elements.length; i++) {
      var item = elements.item(i);
      var found = item.name.match(/cookieConsents\[([a-z]+)\]/i);
      if (found) {
        result[found[1]] = item;
      }
    }
    return result;
  }
  function setCookieConsentsCookie(consentCheckboxes, expirationDate) {
    var cookieConsentsObject = {};
    for (var key in consentCheckboxes) {
      cookieConsentsObject[key] = consentCheckboxes[key].checked;
    }
    cookieConsentsObject[COOKIE_PROPERTY_JS] = Number(new Date());
    var base64Value = btoa(JSON.stringify(cookieConsentsObject));
    document.cookie = "".concat(COOKIE_NAME, "=").concat(base64Value, ";path=/;").concat(expirationDate ? "expires=".concat(expirationDate.toUTCString(), ";") : "");

    // Confirm that cookie was set properly. It means that httpOnly cookie (non-overwrittable) did not exist before.
    return document.cookie.indexOf(COOKIE_NAME) !== -1;
  }
  function getConsentData(consentCheckboxes) {
    var consent = {};
    for (var key in consentCheckboxes) {
      // noinspection JSUnfilteredForInLoop
      var inputElement = consentCheckboxes[key];
      var value = inputElement.checked ? 'granted' : 'denied';
      var codenames = JSON.parse(inputElement.getAttribute(DATA_COOKIE_CONSENT_CODENAMES));
      for (var i in codenames) {
        var snakeCaseKey = codenames[i].replace(/[A-Z]/g, function (letter) {
          return "_".concat(letter.toLowerCase());
        });
        consent[snakeCaseKey] = value;
      }
    }
    return consent;
  }
})();